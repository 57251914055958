.tox-notifications-container {
  display: none!important;
}
.tox-notification {
  display: none!important;
}

.tox-promotion {
  display: none!important;
}

.tox-pop--left {
  display: none!important;
}
.tox-statusbar__branding {
  display: none!important;
}

.tox-label {
  white-space: pre-wrap!important;
}

.tox-editor-header {
  z-index: 0!important;
  border-bottom: 1px solid #e3e4e8!important;
}
