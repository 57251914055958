input,
textarea,
select {
  font-family: $font__name;
}

.ant {
  &-select {
    width: 100%;

    &-selection-search {
      margin-inline-start: 0 !important;
    }

    &-selector {
      background-color: transparent !important;
      border-color: #848ba7 !important;
      border-radius: 4px !important;
      min-height: 36px !important;
      padding: 7px 10px !important;
    }

    &-selection {
      &-search {
        input {
          height: 100%;
        }
      }

      &-item {
        line-height: 26px !important;
        margin-bottom: 3px !important;
        margin-top: 3px !important;

        &-content {
          line-height: 22px !important;
        }

        &-remove {
          align-items: center;
          display: flex !important;
          justify-content: center;
        }
      }
    }
  }
}

.form {
  &-field {
    display: block;
    margin-bottom: 30px;
  }

  &-label {
    color: $color-text-fade;
    cursor: pointer;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    padding-left: 15px;
  }

  &-input {
    border: 1px solid #848ba7;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    width: 100%;

    &._select {
      background-image: url("../../../img/icons/arrow-select.svg");
      background-position: calc(100% - 10px) 50%;
      background-repeat: no-repeat;
      background-size: 12px;
    }

    &._error {
      border-color: #c72626;
    }

    &[type="date"] {
      height: 38px;
      padding: 8px 15px;
    }

    &[disabled] {
      cursor: default;
    }

    &[disabled] {
      color: $color-text-fade;
      cursor: default;
    }
  }

  &-error {
    color: #c72626;
    font-size: 13px;
    margin-top: 5px;
    text-align: left;
  }
}

.checkbox {
  // background: $color-bg;
  border: 1px solid #848ba7;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;

  // &:checked {
  // 	background: url("../../../img/icons/checked.svg") 50% no-repeat;
  // 	background-size: 10px;
  // }
}
