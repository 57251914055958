@import "../../../static/styles/inc/variables";

.modal {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 98;

  &:after {
    background: fade-out(#000, 0.2);
    bottom: 0;
    content: "";
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .block {
    margin: 15px auto;
    max-height: calc(100% - 30px);
    max-width: 1000px;

    &-content {
      height: auto;
    }
  }

  @media #{$mediaMd} {
    .block {
      height: 100%;
      margin: 0;
      width: 100%;
    }
  }
}
