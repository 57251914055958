@import "../../../static/styles/inc/variables";

.footer {
  color: #898fa9;
  padding: 15px 30px 15px 290px;

  a {
    color: #fff;
  }

  @media #{$mediaXl} {
    padding: 15px 30px 15px 30px;
  }
  @media #{$mediaMd} {
    font-size: 13px;
  }
}
