.ant-message-notice {
  text-align: end!important;
}
.ant-message-notice-content {
  padding: 15px 20px;
  border-radius: 12px;
  min-width: 260px;
}
.ant-message-custom-content {
  display: flex;
  justify-content: start;
  align-items: center;
}
