.card {
  border-radius: 0.25rem;
  margin-bottom: 30px;

  &-head,
  &-content,
  &-footer {
    padding: 15px;
  }

  &-header {
    border-radius: 0.25rem 0.25rem 0 0;

    font-size: 1.3rem;
    padding: 0.75rem 1.25rem;
  }

  @media #{$mediaMd} {
    margin: 10px auto 10px auto;
    &-header {
      font-size: 20px;
      padding: 10px;
    }
    &-content {
      padding: 10px;
    }
  }

  @media #{$mediaSm} {
    &-header {
      font-size: 18px;
      padding: 7px;
    }
    &-content {
      padding: 7px;
    }
  }
}
