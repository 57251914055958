.template-summary-report-elements {
  .search-list {
    left: 15px;
    position: absolute;
    right: 15px;
    top: 100%;
    z-index: 1;

    .item {
      padding: 10px;
    }
  }

  .block {
    height: 100%;
  }
}
