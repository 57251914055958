.link-with-plus{
  display: flex;
}
.folders-wrap{
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #1d2029;
  padding: 3px 0 3px 35px;
  max-height: 110px;
  overflow-y: auto;
  margin-top: 10px;
}
.folders-item {
  color:#7b8190;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: #5b73e8;
    transition: all linear .2s;
  }
}
.folders-wrap::-webkit-scrollbar{
  width: 0;
}
.folders-item-icons {
  gap: 6px;

  &_edit{
    &:hover {
      fill: #5b73e8!important;
    }
  }
  svg {
    transition: all linear .2s;

    &:hover {
      fill: #d31544;
      opacity: .6;
      transition: all linear .2s;
    }
  }
}
.invitation-link {
  color:#7b8190;
  padding: 3px 0px 10px 3px;
  font-size: 16px;
  transition: all linear .2s;
  &_active {
    color: #5b73e8;
  }
  &:hover {
    color: #5b73e8;
    transition: all linear .2s;
  }
}
