.ant-picker-range ,.ant-picker {
  border: 1px solid #848ba7;
  color: black;
  outline: none;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;

  & input {
    cursor: pointer;

    &::placeholder {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  &:hover {
    border: 1px solid #848ba7;
  }
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-btn-primary {
  border: 1px solid #5b73e8 !important;
}

.ant-btn-primary:hover {
  background: #5b73e8 !important;
}
