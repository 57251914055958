@import "../../../static/styles/inc/variables";

.tabs-container {
  padding: 25px;
}

.tabs li a {
  color: white;
  display: block;
  padding: 0.8rem 1.2rem;
  position: relative;
  top: 4px;
  transition: all 0.1s ease-in-out;
}

.tabs li.active a,
.tabs li a:hover {
  border: none;
  color: #5b73e8;

  &::after {
    background-color: #5b73e8;
    border-radius: 15px;
    bottom: 0;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }
}

.tabs-content {
  padding: 25px;
  position: relative;
  z-index: 2;
}

.tabs-panel {
  display: none;
}

.tabs-panel.active {
  display: block;
}
