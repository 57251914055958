@import "../../../static/styles/inc/variables";

#menu {
  bottom: 0;
  color: $color-text;
  font-size: 12px;
  left: 0;
  padding: 30px 15px;
  position: fixed;
  top: 56px;
  width: 260px;
  z-index: 97;

  .link-folder {
    .folder {
      padding-left: 35px;
      font-size: 12px;
      margin-top: 10px;
      cursor: pointer;
      color: #c9c9c9;
      transition: all 0.3s;

      &:hover {
        color: #fff;
      }
    }
  }

  .new-message-in-folder {
    position: absolute;
    right: 10px;
    font-size: 12px;
    background-color: #5b73e8;
    border-radius: 300px;
    padding: 2px 8px;
    color: #fff;

    &-with-add {
      right: 50px !important;
    }
  }

  .archive_name {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 0;
    font-size: 17px;
    background: linear-gradient(109deg, rgba(91,115,232,1) -8%, rgba(35, 146, 171, 1) 92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    user-select: none;
    cursor: pointer;
    transition: linear .2s;
    width: fit-content;
     position:relative;

    &:hover {
      opacity: .75;
      transition: linear .2s;
    }

    &:hover .menu-back-icon {
      transition: linear .2s;
      transform: translateX(-6px);
    }

    & .menu-back-icon {
      transition: linear .2s;
      margin-right: 10px;
      margin-left: 6px;
      min-height:24px;
      min-width:24px;
    }

    & .menu-folder-icon {
      min-height:18px;
      min-width:18px;
    }

    & .archive_name_text {
        max-width:160px;
        text-overflow: ellipsis;
        height:24px;
        text-align: center;
        overflow-x: hidden;
        white-space: nowrap;
        line-height: 24px;
    }
  }

  .inbox-exit {
    padding: 5px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: inline-block;
  }

  .add-folder {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;

    svg {
      transition: all 0.3s;
    }

    &:hover {
      svg {
        fill: #5b73e8;
      }
    }
  }

  .link {
    align-items: center;
    color: $color-text;
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 6px 0;
    padding-left: 35px;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
    padding-right: 45px;
    text-overflow: ellipsis;
    overflow: hidden;

    svg {
      left: 0;
    }

    path {
      transition: all 0.2s;
    }

    .msg-num {
      background-color: #5b73e8;
      border-radius: 15px;
      color: white;
      font-size: 11px;
      line-height: 1;
      padding: 5px 10px 3px;
      right: 10px;
    }
  }

  .add-folder-plus-icon {
    cursor: pointer;
    transition: all linear .3s;

    &:hover {
      opacity: .8;
      transition: all linear .3s;
    }
  }

  .collapse-arrow {
    transition: all linear .2s;
    margin-left: 6px;
  }

  .collapse-arrow-rotate {
    transform: rotateX(-180deg);
    transition: all linear .2s;
  }

  @media #{$mediaXl} {
    left: -100%;
    top: 53px;
    transition: left 0.3s;
  }
  @media #{$mediaLg} {
    top: 53px;
    left: -100%;
    transition: left 0.3s;
    &._visible {
      left: 0;
    }
  }
}
