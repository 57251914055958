.reports {
  .filtration-loupe {
    height: 20px;
    width: 20px;
  }

  .notActual {
    color: rgb(133, 133, 133);
  }
}
