h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.8rem;
}

h3,
.h3 {
  font-size: 1.575rem;
}

h4,
.h4 {
  font-size: 1.35rem;
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6,
p {
  font-size: 0.9rem;
}

.f {
  /* Text case */
  &-upper {
    text-transform: uppercase;
  }

  &-lower {
    text-transform: lowercase;
  }

  /* Font weight */
  &-900 {
    font-weight: 900;
  }

  &-800 {
    font-weight: 800;
  }

  &-700 {
    font-weight: 700;
  }

  &-600 {
    font-weight: 600;
  }

  &-500 {
    font-weight: 500;
  }

  &-400 {
    font-weight: 400;
  }

  &-300 {
    font-weight: 300;
  }
}

/* Text align */
.ta {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}
