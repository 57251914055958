@import "../../../../static/styles/inc/variables";

.users {
  .edit-user {
    .btn {
      img {
        width: 15px;
      }
    }
  }
}

.notActual {
  color: #6c6c6c;
}
