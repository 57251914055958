.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 300;
  user-select: none;

  input[type="checkbox"] {
    appearance: none;
    background-color: #3a3e50;
    border: #50556d solid 1px;
    border-radius: 0.25rem;
    cursor: pointer;
    height: 16px !important;
    margin-right: 9px;
    width: 16px;

    &:checked {
      background: #5b73e8 url("../../../img/ok.svg") 50% no-repeat;
      background-size: 10px auto;
      border-color: #5b73e8;
    }
  }
}
