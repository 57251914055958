.error_active {
  @apply invisible opacity-0 translate-y-6
}


.form-disable {
  @apply pointer-events-none
}


.var-form-wrap {
  & input {
    position: relative;
    z-index: 60;
  }
}
