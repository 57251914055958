@import "../../../../static/styles/inc/variables";

.mfo {
  .create-mfo {
    height: 100%;
  }

  .small-input {
    font-size: 13px;
    margin-top: 5px;
    padding: 6px !important;
  }

  .name-comp {
    img {
      height: 15px;
      width: 15px;
    }
  }

  .info-content-cart {
    color: $color-text;

    a,
    button {
      img {
        height: 20px;
        width: 20px;
      }
    }

    .des-teg {
      color: white;
    }
  }

  .examples {
    .actuality {
      label {
        width: 25px;
      }
    }
  }

  .create-mfo {
    input {
      width: 100%;
    }
  }
}
