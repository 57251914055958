@import "../variables";

.block {
  background: #2b2e3b;
  border: 1px solid #2b2e3b;
  border-radius: 0.4rem;

  &-head,
  &-content,
  &-bottom {
    padding: 15px;
  }

  &-head {
    font-size: 1rem;
    position: relative;

    .close {
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }

  &-content {
  }
}
