.custom-checkbox {
  background: #dd3c3c;
  border-radius: 22px;
  cursor: pointer;
  height: 20px;
  position: relative;
  transition: all 0.3s;
  width: 38px;
  z-index: 6;

  .circle {
    background: #fff;
    border-radius: 100%;
    height: 16px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: all 0.3s;
    width: 16px;
  }

  &._active {
    background-color: #25ca23 !important;

    .circle {
      left: 19px;
    }
  }
}
