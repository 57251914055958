@import "../../../static/styles/inc/variables";

.cmp-breadcrumbs {
  li {
    display: inline-flex;

    a {
      color: inherit;
      text-decoration: underline;
    }

    &:not(:last-child) {
      &:after {
        content: "/";
        display: inline-block;
        margin: 0 6px;
      }
    }
  }
}
