.badge-tab {
  position: relative;

  .badge {
    transition: all 0.3s;

    .rotate {
      transform: rotate(180deg);
    }
  }

  .list {
    border: 1px solid #ced4da;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background-color: #ebebeb;
    padding: 5px 0;
    font-size: 12px;
    color: #343747;
    border-radius: 6px;
    z-index: 3;
    box-shadow: 0px 12px 14px -2px rgba(34, 60, 80, 0.44);

    .str {
      cursor: pointer;
      transition: all 0.3s;
      padding: 5px 10px;

      &:hover {
        background-color: #d1d1d1;
      }
    }
  }
}
