@import "./variables";

html {
  font-size: 16px;
}

html,
body {
  height: 100%;
}

body {
  color: #9699a8;
  font: 400 16px/1.4 $font__name;
}

.content-block {
  padding: 90px 30px 30px 290px;
  width: 100%;

  @media #{$mediaXl} {
    padding: 90px 15px 50px 15px;
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

/* Position */
[class*="pos-abs"] {
  position: absolute;
}

.pos {
  position: relative;
  z-index: 1;

  &-abs {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-abs-y {
    top: 50%;
    transform: translate(0, -50%);
  }

  &-abs-x {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

/* Image */
[class*="image-"] {
  height: 100%;
  width: 100%;
}

.image {
  &-cover {
    object-fit: cover;
  }

  &-contain {
    object-fit: contain;
  }
}

/* Scroll */
[class*="scroll-"] {
  -webkit-overflow-scrolling: touch;
}

.scroll {
  &-y {
    overflow: hidden auto;

    &-custom {
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
      }
    }
  }

  &-x {
    overflow: auto hidden;
    padding-bottom: 26px;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
    }
  }
}

.sort {
  background-position: calc(100% - 9px) 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  cursor: pointer;
  height: 20px;
  padding-right: 30px;
  width: 20px;

  &._asc {
    background-image: url("../../img/icons/sort-arrow.svg");
  }

  &._desc {
    background-image: url("../../img/icons/sort-arrow-up.svg");
  }
}

/* Bacground Alert */
.react-confirm-alert-overlay {
  background-color: #292929ab !important;
}

.react-confirm-alert-button-group {
  button:nth-child(1) {
    background-color: #5b73e8 !important;
  }

  button:nth-child(2) {
    background-color: #f46a6a !important;
  }
}

/* Cursor */
.cursor {
  &-pointer {
    cursor: pointer !important;
  }

  &-default {
    cursor: default !important;
  }
}

.notActual:hover,
.go-link:hover {
  transition: all 0.2s;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
