@import "../../../static/styles/inc/variables";

.filter {
  label {
    select {
      appearance: none;
      background: url("../../../static/img/icons/arrow-select.svg") no-repeat center;
      background-position: calc(100% - 10px) 50%;
      background-size: 15px;
      border: 1px solid $color-text;
      border-radius: 6px;
      color: $color-text;
      font-size: 14px;
      outline: 0;
      padding: 11px 32px 11px 11px;
      width: 100%;
    }
  }
}
