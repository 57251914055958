.ant-select, .ant-select-multiple, .ant-select-show-search {
  flex: 1;
  width: 100%;
}

.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
}

.ant-select-focused {
  border: none;
  outline: none;
}

.ant-select-focused:focus {
  outline: none;
}

.ant-select-selector {
  position: relative;
}

.ant-select-custom-clear-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 6px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: all linear .2s;
  cursor: pointer;
  z-index: 200;

  &:hover {
    opacity: .7;
    transition: all linear .2s;

  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #9699a8 !important;
  box-shadow: none !important;
}

.ant-custom-tag {
  gap: 7px;
  background: hsl(0, 17%, 98%);
  border-radius: 6px;
  padding: 1px 8px;
  display: flex;
  margin: 1px;
  position: relative;

  &-label {
    margin-right: 16px;
    font-weight: 500;
  }

  &-cancel {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    border-radius: 0 6px 6px 0;
    bottom: 0;
    cursor: pointer;
    transition: all linear .2s;

    &:hover {
      background-color: #FFBDAD;
      transition: all linear .2s;

      & svg {
        transition: all linear .2s;
        fill: #DE350B;
      }
    }
  }
}

