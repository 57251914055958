@import "../../../static/styles/inc/variables";

.badge {
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  padding: 4px 10px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;

  &-blue {
    background-color: #39a1dd;
  }

  &-pale-red {
    background-color: #f26d6d;
  }

  &-red {
    background-color: #f04444;
  }

  &-dark-red {
    background-color: #dd1010;
  }

  &-dark-blue {
    background-color: #1657ba;
  }

  &-green {
    background-color: #34c38f;
  }

  &-info {
    background-color: #50a5f1;
  }

  &-warning {
    background-color: #f1b44c;
    color: #f8f9fa;
  }

  &-danger {
    background-color: #f17c7c;
  }

  &-white {
    background-color: #ebebeb;
    color: #343747;
  }
}
