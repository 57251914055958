.view-organization-reports {
  svg {
    transition: all 0.3s;
  }

  .icon-rotated {
    transform: rotate(180deg);
  }

  textarea {
    width: 100%;
  }

  .checkbox {
    input {
      width: 24px;
    }
  }

  ._error {
    color: #f14646;
    font-size: 13px;
    display: block;
  }
}
