@import "../../../../static/styles/inc/variables";

.edit-report {
  input {
    margin: auto 0;
    width: 100%;
  }

  .modal {
    textarea {
      height: 300px;
      width: 100%;
    }
  }

  .checkbox {
    margin-right: 8px;
    width: 20px;
  }
}
