@import "../../../../static/styles/inc/variables";

.view-report {
  .searched-block {
    background-color: #fdfdff;
    border-radius: 8px;
    max-height: 93px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 40px;
    width: 100%;

    .searched-item {
      color: rgb(83, 83, 83);
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: #c2c2c2;
      }
    }
  }

  table {
    width: 100%;
  }

  .btn-lock {
    background-color: #8a8a8a54;
    cursor: default;
    color: #ffffff78;

    &:hover {
      background-color: #8a8a8a54;
      cursor: default;
      color: #ffffff78;
    }
  }

  .checkbox {
    margin-right: 8px;
    width: 20px;
    cursor: default;
  }

  // ::-webkit-scrollbar {
  // 	height: 2px; /* высота для горизонтального скролла */
  // 	width: 6px; /* ширина для вертикального скролла */
  // }

  // ::-webkit-scrollbar-thumb {
  // 	background-color: #535353;
  // 	border-radius: 2em;
  // }

  .edit-trigger {
    textarea {
      height: 120px;
      resize: none;
      width: 100%;
    }
  }

  @media #{$mediaMd} {
    .card-footer {
      button {
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  .name {

  }
}
