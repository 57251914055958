._light {
  background-color: #fff;
  body {
    background-color: #fff;
  }
  .header {
    background: #e7e7e7;
  }

  .theme {
    img {
      display: none;
    }

    span {
      color: #7b8190 !important;
    }
  }

  .ql-editor {
    p,
    span {
      color: #7b8190 !important;
      background: none !important;
    }
  }

  .mail-msg {
    img {
      max-width: 100px;
      object-fit: contain;
    }

    p,
    span {
      color: #7b8190 !important;
      background: none !important;
    }
  }

  .calendar {
    .card-content {
      strong {
        color: #495057 !important;
      }

      span {
        color: #495057 !important;
      }

      a {
        color: rgb(98, 98, 255) !important;
      }
    }
  }

  .footer {
    .made {
      a {
        color: #848484;
      }
    }
  }

  #menu {
    background-color: #f3f3f3;

    .link {
      color: #7b8190;

      &:hover,
      &._active {
        path {
          fill: #5b73e8 !important;
        }
      }

      path {
        fill: #7b8190 !important;
      }
    }

    .name span {
      color: #7b8190;
    }

    .link:hover,
    .link._active {
      color: #5b73e8;
    }
  }

  .card {
    background-color: #f3f3f3;
    color: #495057;

    &-header {
      background-color: #e7e7e7;
      color: #495057;
    }

    &-footer {
      border-top: 1px solid #ced4da;
      background-color: #f6f6f6;
    }
  }

  table {
    thead {
      background-color: #e7e7e7;
    }

    tbody {
      tr {
        border-bottom: 1px solid #ced4da;
      }
    }
  }

  .form {
    &-input {
      color: #495057;
    }
  }

  .go-link:hover {
    background-color: #dadadaa6;
  }

  .select-company {
    color: #22252f;

    .select__input {
      color: #22252f !important;
    }

    .select__single-value {
      color: #22252f;
    }

    .select__control {
      border: 1px solid #848ba7;
      background-color: #f3f3f3 !important;
    }
  }

  .basic-multi-select {
    padding: 0;

    .select__input {
      color: #22252f !important;
    }

    .select__control {
      border: 1px solid #848ba7;
      border-radius: 5px;

      font-size: 14px;
    }

    .selct__menu {
      background-color: #22252f !important;
      color: #22252f;
    }

    .select__multi-value {
      background-color: #d0d0d0 !important;

      .select__multi-value__label {
        color: #22252f !important;
      }
    }
  }

  .page-inbox {
    .new-msg {
      background: #cfcfcf !important;

      &:hover {
        background-color: #00000038 !important;
      }
    }
  }

  .message {
    background-color: #ebebeb;
    border-bottom: 1px solid #ced4da;
    color: #7b8190;

    &:hover {
      background-color: #a5a5a538;
    }

    &._new {
      background: #000000;
    }
  }

  .cmp-breadcrumbs {
    color: #7b8190;
  }

  .pagination {
    background: #e7e7e7;
    box-shadow: inset 0 0 0 1px #878d9c;

    .link,
    .break {
      &:hover {
        color: #000;
      }

      &:not(:last-child) {
        border-right: 1px solid #878d9c;
      }
    }
  }

  .chat-files {
    .file {
      background-color: #d9d9d9;
    }
  }

  .block {
    background: #e7e7e7;
    border: 1px solid #e7e7e7;

    .close {
      background: #cecece;
    }

    &-head {
      border-bottom: 1px solid #ced4da;
      color: #495057;
    }

    &-bottom {
      background: #e7e7e7;
      border-bottom: 1px solid #ced4da;
    }
  }

  .chat-area {
    color: #495057;
  }

  .search-list {
    background: #c2c2c2;

    .item {
      &:hover {
        background: #afafaf;
      }
    }
  }

  .accordion {
    background-color: #c2c2c2;
    border-bottom: 0 solid #c2c2c2;
    color: #000;
  }

  .select__control {
    background-color: #e7e7e7;
    color: #7b8190;
  }

  .dropzone {
    background: #d9d9d9;
    border: 2px dashed #7b8190;
    color: #7b8190;
  }

  .scroll {
    &-y {
      &-custom {
        &::-webkit-scrollbar {
          background-color: #ffffff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #b4b4b4;
        }
      }
    }

    &-x {
      &::-webkit-scrollbar-thumb {
        background-color: #b4b4b4;
        border-radius: 20px;
      }
    }
  }

  .checkbox {
    background-color: #e5e5e5;

    &:checked {
      background: #e5e5e5 url("../../../img/icons/checkedLightTheme.svg") 50% no-repeat;
      background-size: 10px;
    }
  }

  .sort {
    background-image: url("../../../img/icons/sort-arrow-fadeLightTheme.svg");
  }

  .page-inbox {
    .msg {
      .text {
        background: #cecece;
        color: #000;
      }
    }

    ._me {
      .text {
        color: #fff;
      }
    }

    .file-download {
      .item {
        background: #cecece;
        color: #000;
      }
    }
  }

  .paperClip {
    path {
      fill: #000;
    }
  }

  .breadcrumbs-li {
    color: rgba(0, 0, 0, 0.41);
  }
}
