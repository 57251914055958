@import "../../../../static/styles/inc/variables";

.create-template {
  input {
    color: #fff;
    margin: auto 0;
    width: 100%;
  }

  .checkbox {
    margin-right: 8px;
    width: 20px;
  }
}
