@import "../../../../static/styles/inc/variables";

.page-inbox {
  .card-content {
    overflow: hidden;
  }

  .theme-message {
    width: 300px;
  }

  .time {
    color: #9699a8;
  }

  .badge {
    // display: block;
    // margin-left: auto;
    width: 100%;
  }

  .date {
    display: inline-grid;
    margin-left: 20px;
    width: 150px;
  }

  .blocked-btn {
    background-color: #8f8f8f4f !important;
  }

  .start {
    width: 250px;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    display: table;
    margin-right: 15px;

    .org-name {
      width: 250px;

      @include text-overflow(1);
    }
  }

  .theme {
    font-size: 14px !important;
    line-height: 1.7;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span {
      font-size: 14px !important;
      line-height: 1.7;

      span {
        font-size: 14px !important;
        line-height: 1.7;
      }
    }

    @include text-overflow(1);

    span {
      background: none !important;
    }
  }

  .editor {
    .ql-editor {
      min-height: 300px;
      max-height: 700px;
    }
  }

  ._error {
    color: rgb(244, 106, 106);
    font-size: 13px;
    display: block;
  }

  .chat-list {
    color: #fff;
    height: 100%;

    ._me {
      margin-left: auto;
      text-align: end;
      word-break: break-word;

      .text {
        background-color: #5b73e8 !important;
        border-radius: 8px 8px 0 8px !important;
      }

      .time {
        text-align: end;
      }
    }

    .msg {
      .text {
        border-radius: 8px 8px 8px 0px;
        display: inline-block;
        font-size: 13px;
      }

      .file-download {
        display: flex;
        flex-direction: column;

        .item {
          border-radius: 6px;
          cursor: pointer;
          padding: 0.5rem;
          max-width: 500px;
          text-align: left;

          .img-doc {
            margin: auto 0;

            img {
              background-color: rgb(214, 214, 214);
              border-radius: 4px;
              margin-right: 10px;
              padding: 4px;
              width: 30px;
            }
          }

          .size {
            color: #9699a8;
            font-size: 0.7rem;
          }
        }
      }

      .time {
        font-size: 10px;
      }
    }
  }

  .open-file {
    background-color: #5b73e8;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: auto;
    min-height: 35px;
    padding: 5px 10px;

    svg {
      margin: auto;
    }
  }

  .form-chat {
    width: 100%;

    .chat-area {
      border: 1px solid rgb(126, 126, 126);
      border-radius: 4px;

      min-height: 35px;
      padding: 5px;
      resize: none;
      width: 100%;
    }
  }

  .send-btn {
    background-color: #5b73e8;
    border-radius: 4px;
    margin-left: 8px;
    margin-top: auto;
    min-height: 35px;
    padding: 5px 10px;

    svg {
      margin: auto;
    }
  }

  .chat-files {
    .file {
      border-radius: 5px;

      &-doc {
        background: #5b73e8;
        border-radius: 5px;
        height: 38px;
        width: 38px;
      }

      .remove-btn {
        height: 38px;
        padding: 0;
        width: 38px;
      }
    }
  }

  // @media #{$mediaXxl} {
  // 	.theme {
  // 		width: 360px;
  // 	}
  // }
  // @media #{$mediaXl} {
  // 	.theme {
  // 		width: 500px;
  // 	}
  // }
  // @media #{$mediaLg} {
  // 	.theme {
  // 		width: 450px;
  // 	}
  // }
  // @media #{$mediaMd} {
  // 	.start {
  // 		width: 100px;
  // 	}
  // }
  // @media #{$mediaSm} {
  // 	.start {
  // 		width: 50px;
  // 	}
  // }
}
