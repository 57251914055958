@import "~antd/dist/antd.min.css";

/* Global */
@import "./inc/_grid.scss";
@import "./inc/_fonts.scss";
@import "./inc/_reset.scss";
@import "./inc/_custom.scss";
@import "./inc/_variables.scss";
@import "./inc/ui/alert";
@import "./inc/ui/table";
@import "./inc/ui/_block.scss";
@import "./inc/ui/card";
@import "./inc/ui/button";
@import "./inc/ui/text";
@import "./inc/ui/checkbox";
@import "./inc/ui/customCheckbox";
@import "./inc/ui/select";
@import "./inc/ui/picker";
@import "./inc/ui/form";
@import "./inc/ui/editor";
@import "./inc/theme/light";
@import "./inc/theme/dark";


@tailwind base;
@tailwind components;
@tailwind utilities;
