@import "../../../static/styles/inc/variables";

.dropzone-wrap {
  ._error-file {
    border: 1px solid #f14646 !important;
    display: block;
  }

  .dropzone {
    border-radius: 4px;
    cursor: pointer;
    height: 200px;
    padding: 30px;
    text-align: center;
    width: 100%;

    .accepted-files {
      font-size: 13px;
      font-weight: 400;
      margin-top: 10px;
      opacity: 0.6;
    }
  }

  .file {
    border: 1px solid #565e77;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 10px;
    padding: 8px;

    &-img {
      background: #fff;
      border-radius: 4px;
      height: 42px;
      overflow: hidden;
      width: 42px;

      .doc-icon {
        height: 26px;
        width: 26px;
      }
    }

    &-size {
      font-size: 12px;
      opacity: 0.4;
    }

    &-remove {
      height: 42px;
      width: 42px;
    }
  }
}
