._dark {
  background-color: #22252f;

  body {
    background-color: #22252f;
  }

  .header {
    background: #2d313e;
  }

  .mail-msg {
    img {
      max-width: 100px;
      object-fit: contain;
    }
  }

  .theme {
    img {
      display: none;
    }

    span {
      color: #fff !important;
    }
  }

  .ql-editor {
    p,
    span {
      color: #fff !important;
      background: none !important;
    }
  }

  .calendar {
    .card-content {
      strong {
        color: #fff !important;
      }

      span {
        color: #fff !important;
      }

      a {
        color: rgb(98, 98, 255) !important;
      }
    }
  }

  #menu {
    background-color: #2b2e3b;

    .link {
      color: #fff;

      &:hover,
      &._active {
        path {
          fill: #5b73e8 !important;
        }
      }

      path {
        fill: #fff !important;
      }
    }

    .name span {
      color: #fff;
    }

    .link:hover,
    .link._active {
      color: #5b73e8;
    }
  }

  .card {
    background-color: #2b2e3b;
    color: #fff;

    &-header {
      background-color: #343747;
      color: #f6f6f6;
    }

    &-footer {
      border-top: 1px solid #22252f;
      background: #282b38;
      color: #fff;
    }
  }

  table {
    thead {
      background-color: #343747;
    }

    tbody {
      tr {
        border-bottom: 1px solid #343747;
      }
    }
  }

  .form {
    &-input {
      background: #22252f;
      color: #fff;

      &._select {
        background-image: url("../../../img/icons/arrow-select.svg");
        background-position: calc(100% - 10px) 50%;
        background-repeat: no-repeat;
        background-size: 12px;
      }
    }
  }

  .go-link:hover {
    background-color: #2f313fa6;
  }

  .select-company {
    color: #22252f;

    .select__input {
      color: #fff !important;
    }

    .select__single-value {
      color: #fff;
    }

    .select__control {
      border: 1px solid #848ba7;
    }
  }

  .basic-multi-select {
    padding: 0;

    .select__input {
      color: #fff !important;
    }

    .select__control {
      border: 1px solid #848ba7 !important;
      border-radius: 5px;

      font-size: 14px;
    }

    .selct__menu {
      background-color: #22252f !important;
      color: #fff;
    }

    .select__multi-value {
      background-color: #363740 !important;

      .select__multi-value__label {
        color: #fff !important;
      }
    }
  }

  .page-inbox {
    .new-msg {
      background: #4e5367 !important;

      &:hover {
        background-color: #00000038 !important;
      }
    }

    .message {
      background: #343847;
      border-bottom: 1px solid #00000038;
      color: #fff;
      transition: all 0.3s;

      &:hover {
        background-color: #00000038;
      }

      &._new {
        background: #343643;
      }
    }
  }

  .chat-files {
    .file {
      background: $color-bg;
    }
  }

  .cmp-breadcrumbs {
    color: #fff;
  }

  .pagination {
    background: #2b2e3b;
    box-shadow: inset 0 0 0 1px #343747;

    .link,
    .break {
      &:hover {
        color: #fff;
      }

      &:not(:last-child) {
        border-right: 1px solid #343747;
      }
    }
  }

  .block {
    background: #2b2e3b;
    border: 1px solid #2b2e3b;

    .close {
      background: $color-bg;
    }

    &-head {
      border-bottom: 1px solid $color-bg;
      color: #fff;
    }

    &-bottom {
      background: #282b38;
      border-top: 1px solid $color-bg;
    }
  }

  .chat-area {
    color: #fff;
  }

  .search-list {
    background: #22252f;

    .item {
      &:hover {
        background: #1d2029;
      }
    }
  }

  .accordion {
    background-color: #343747;
    border-bottom: 0 solid #343747;
    color: #f6f6f6;
  }

  .select__control {
    background-color: #22252f;
    color: #fff;
  }

  .dropzone {
    background: #2f3341;
    border: 2px dashed #565e77;
  }

  .scroll {
    &-y {
      &-custom {
        &::-webkit-scrollbar {
          background-color: #2d313e;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #b4b4b4;
        }
      }
    }

    &-x {
      &::-webkit-scrollbar-thumb {
        background-color: #b4b4b4;
        border-radius: 20px;
      }
    }
  }

  .checkbox {
    background: $color-bg;

    &:checked {
      background: $color-bg url("../../../img/icons/checked.svg") 50% no-repeat;
      background-size: 10px;
    }
  }

  .sort {
    background-image: url("../../../img/icons/sort-arrow-fade.svg");
    transition: all linear .2s;
  }

  .page-inbox {
    .select-company {
      color: #22252f;

      .select__input {
        color: #fff !important;
      }

      .select__single-value {
        color: #fff;
      }

      .select__control {
        border: 1px solid #848ba7;
        border-radius: 5px;

        font-size: 14px;
      }
    }

    .basic-multi-select {
      padding: 0;

      .select__input {
        color: #fff !important;
      }

      .select__control {
        border: 1px solid #848ba7;
        border-radius: 5px;

        font-size: 14px;
      }

      .selct__menu {
        background-color: #22252f !important;
        color: #fff;
      }

      .select__multi-value {
        background-color: #363740 !important;

        .select__multi-value__label {
          color: #fff !important;
        }
      }
    }

    .msg {
      .text {
        background: #22252f;
      }
    }

    .file-download {
      .item {
        background: #5f5f5f75;
      }
    }
  }

  .paperClip {
    path {
      fill: #fff;
    }
  }

  .ql-toolbar {
    & span {
      color: white;
    }

    & .ql-stroke {
      stroke: white !important;
    }
  }

  .breadcrumbs-li {
    color: white;
  }

  .ant-descriptions-item-label, .ant-descriptions-item-content {
    background: #22252f !important;
    color: white;
  }

  .ant-select-selector {
    background: #22252f !important;
    border: 1px solid #848ba7 !important;
  }

  .ant-select-selection-placeholder {
    color: white !important;
  }

  .ant-select-custom-clear-icon svg {
    fill: white;
  }

  .ant-custom-dropdown-select {
    background: #35363f;
  }

  .ant-picker-range, .ant-picker {
    background: #22252f;

    & svg {
      fill: white;
    }

    & input {
      color: rgb(255, 255, 255) !important;

      &::placeholder {
        color: rgb(255, 255, 255) !important;
      }
    }
  }


  .ant-picker-dropdown-range, .ant-picker-dropdown {
    border: 1px solid rgba(255, 255, 255, 0.6);

    & * {
      background: #22252f !important;
      color: white !important;
    }
  }

  .ant-popover {

    &-inner {
      background: #22252f !important;

      border: 1px solid rgba(255, 255, 255, 0.6);

      &-content {
        color: white !important;
      }
    }
  }

  .custom-editor {
    & #tinymce {
      & * {
        color: white!important;
      }
    }

    &  .tox-edit-area__iframe{
      background: #66676e;
    }

    & .tox-editor-header, .tox-menubar, .tox-toolbar-overlord, .tox-toolbar__primary, {
      background: #2b2e3b !important;
      color: white;

      & svg {
        fill: white;
      }

      & button {
        background: #848ba7;
        cursor: pointer;

        & * {
          cursor: pointer;
        }

        &:hover {
          opacity: .7;
        }
      }
    }

    & .tox-mbtn__select-label ,.tox-tbtn__select-label{
      color: white;
    }
    & .tox-statusbar {
      background: #2b2e3b !important;

      & * {
        color: white;
      }
    }


    & .tox-statusbar__resize-handle svg{
      fill: white;
    }
    & .tox-statusbar__wordcount ,.tox-statusbar__path-item {
      &:hover {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }

  .ant-checkbox-wrapper {
    & span {
      color: white;
    }
  }

  .ant-select-selection-search-input {
    color: white;
  }
}
