@import "../../../static/styles/inc/variables";

.header {
  left: 0;
  padding: 28px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;

  .check-theme {
    label {
      display: flex;
    }

    .switch {
      width: 40px;
      height: 22px;
      background: #919191;
      margin: 0;
      padding: 0;
      appearance: none;
      border: none;
      cursor: pointer;
      position: relative;
      border-radius: 16px;
      transition: 0.3s all;

      .circle {
        transition: 0.3s all;
        width: 20px;
        position: absolute;
        left: 1px;
        top: 1px;
        height: 20px;
        background: #ffffff;
        border-radius: 100%;
      }
    }

    .active {
      background: #4cd964 !important;

      .circle {
        left: 19px !important;
      }
    }
  }

  .mob-menu {
    width: 15px;
  }

  .logo {
    width: 100px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .header-row {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .right-content {
    align-items: center;
    position: absolute;
    right: 35px;

    .organiztion-menu {
      background-color: #343747;
      border: 1px solid $color-text;
      left: 0;
      position: absolute;
      right: 0;
      top: 45px;

      span {
        border-bottom: 1px solid $color-text;
        color: $color-text;
        padding: 15px;
      }
    }

    .company {
      color: $color-text;
      cursor: pointer;

      .comp {
        cursor: pointer;
      }

      .company-name {
        color: #c3c7d7;
      }

      img {
        height: 20px;
        transition: all 0.3s;
        width: 20px;
      }

      .rotate-arrow {
        transform: rotate(180deg);
        transition: all 0.3s;
      }
    }

    .mail-link {
      position: relative;
    }

    .email {
      height: 24px;
      width: 24px;
    }

    .new-msg {
      background: #f46a6a;
      border-radius: 500px;
      height: 10px;
      position: absolute;
      right: -3px;
      top: 0;
      width: 10px;
    }

    .user {
      align-items: center;
      color: $color-text;
      color: #9699a8;
      font-size: 15px;
      margin-left: 10px;
      margin-right: 10px;

      img {
        border: 1px solid grey;
        border-radius: 500px;
        margin-right: 15px;
        width: 33px;
      }
    }
  }

  @media #{$media475} {
    .right-content {
      right: 15px;

      .company {
        img {
          height: 20px;
          width: 20px;
        }
      }

      .email {
        height: 20px;
        margin-right: 5px;
        width: 20px;
      }

      .new-msg {
        right: 0px;
      }

      .user {
        margin-left: 5px;

        img {
          margin-right: 8px;
          width: 30px;
        }
      }
    }
  }
}
