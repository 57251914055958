@import "../../../static/styles/inc/variables";

.accordeon-block {
  .panel {
    color: #9699a8;
    display: none;
    padding: 0 15px;

    p {
      padding: 1.25rem;
    }

    &._visiblity {
      display: block;
    }
  }

  .accordion {
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    outline: none;
    padding: 0.75rem 1.25rem;
    text-align: left;
    transition: 0.5s;
    width: 100%;

    .icon-accordeon {
      transition: all 0.3s;
      width: 20px;
    }

    &__panel {
      font-size: 14px;
      padding: 10px;
    }

    .rotated {
      transform: rotate(45deg);
    }
  }

  @media #{$mediaMd} {
    .panel {
      p {
        padding: 0.5rem;
      }
    }
  }
}
