.page-message {
  .blocked-btn {
    background-color: #8f8f8f4f !important;
  }

  .childrens {
    padding: 30px 30px;
  }

  .left-border {
    border-left: 5px solid #7c7c7c52;
  }

  .mail {
    border-bottom: 1px solid #72727236;

    &-msg {
      padding-left: 30px;
    }
  }

  .small-date {
    font-size: 13px;
  }

  .file-mail {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    color: #5a6fef;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: #5465cc;
    }
  }

  .editor {
    .ql-editor {
      min-height: 300px;
      max-height: 700px;
    }
  }

  .files {
    display: flex;
    flex-direction: column;
  }
}
