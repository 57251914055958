.low-table {
  min-width: 600px;
}

.middle-table {
  min-width: 900px;
}

.big-table {
  min-width: 1134px;
}

table {
  width: 100%;

  thead {
    border-radius: 0.25rem 0.25rem 0 0;

    th {
      padding: 15px 10px;
      text-align: left;

      .form {
        &-field {
          margin-bottom: 0 !important;
        }

        &-input {
          margin-top: 6px;
          padding: 8px 12px;
        }
      }

      &.sort {
        background-image: url("../../../img/icons/sort-arrow-fade.svg");
        background-position: calc(100% - 9px) 50%;
        background-repeat: no-repeat;
        background-size: 12px;
        cursor: pointer;
        padding-right: 30px;

        &._asc {
          background-image: url("../../../img/icons/sort-arrow.svg");
        }

        &._desc {
          background-image: url("../../../img/icons/sort-arrow-up.svg");
        }
      }
    }
  }

  tbody {
    td {
      padding: 10px;
      vertical-align: middle;
    }

    .action-btn {
      height: 38px;
      padding: 0;
      width: 38px;
    }
  }
}
