.view-user {
  input {
    transition: all 0.3s;
  }

  .closed {
    color: #afb1b9;
    cursor: default;
  }

  .card-content {
    img {
      width: 15px;
    }
  }
}
