@import "../../../../static/styles/inc/variables";

.edit-summary {
  ._error {
    color: #c72626;
    font-size: 13px;
    display: block;
  }

  ._error {
    .select__control {
      border: 1px solid #c72626 !important;
    }
  }

  .tasks__list {
    max-width: 500px;
    width: 100%;

    .tasks__item {
      background-color: #cecece;
      border-radius: 10px;
      color: #343747;
      cursor: pointer;
      margin-bottom: 10px;
      padding: 5px 10px;
      position: relative;
      text-align: start;

      transition: background-color 0.5s;
      height: 32px !important;

      span {
        width: calc(100% - 50px);
        @include text-overflow(1);
      }

      img {
        height: 8px;
        width: 8px;
      }
    }

    .selected {
      opacity: 0.6;
    }

    .disabled {
      background-color: #343747;
      color: #fff;
    }

    .custom-checkbox {
      position: absolute;
      right: 10px;
      top: 6px;
    }
  }

  .sorting-ul {
    width: 100px;
  }

  ul {
    li {
      margin-bottom: 10px;
      max-height: 32px;
      padding: 5px;
    }
  }

  @media #{$mediaMd} {
    .tasks__list {
      width: 166px;
    }
    .sorting-ul {
      width: 70px;
    }
  }
}
