@import "../../../../static/styles/inc/variables";

.info {
  table {
    tbody {
      .upload {
        color: #729972;
      }

      .not-upload {
        color: #be7c7c;
      }

      .des {
        text-align: left;
      }
    }
  }
}
