@import "../../../static/styles/inc/variables";

.pagination {
  border-radius: 0.25em;
  overflow: hidden;
  // li {
  // 	cursor: pointer;
  // }

  .break a {
    display: block;
    height: 36px;
    width: 36px;
  }

  .link,
  .break {
    color: #9699a8;
    cursor: pointer;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 36px;
  }

  .active {
    background: #5b73e8;

    border-color: #5b73e8;

    a {
      color: #fff !important;
    }
  }

  @media #{$mediaSm} {
    .link {
      width: 26px;
    }
  }
}
