/* Font size */
$font__name: "SFUIDisplay", Arial, sans-serif;

/* Colors */

body {
}

$color-bg: #22252f;
$color-text: #fff;
$color-text-fade: #9699a8;
$color-hover: #5b73e8;

/* Functions */
@mixin text-overflow($lines) {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-moz-placeholder,
  &:-ms-input-placeholder {
    color: $color;
  }
}

/* Media */
$mediaXxl: "(max-width: 1599.98px)";
$mediaXl: "(max-width: 1199.98px)";
$mediaLg: "(max-width: 991.98px)";
$mediaMd: "(max-width: 767.98px)";
$mediaSm: "(max-width: 575.98px)";
$media475: "(max-width: 475.98px)";
$media375: "(max-width: 375.98px)";
$media320: "(max-width: 320.98px)";
