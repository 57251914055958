@import "../../../../static/styles/inc/variables";

.edit-trigger {
  .tree-wrap {
    max-height: 450px;
  }

  .search-list {
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;

    .item {
      padding: 10px;
    }
  }
}
